<template>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label>{{ label }}</label>
    </b-col>
    <b-col cols="12" sm="7">
      <image-upload
        :image-object="valueFromDb"
        @upload-image-object="updateUploadImageObject"
        @file-removed="$emit('file-removed', null)"
      />
      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <b-form-text v-if="desc" v-html="desc"></b-form-text>
    </b-col>
  </b-row>
</template>

<script>
import ImageUpload from '@/components/helper/ImageUpload.vue';

export default {
  name: 'ImageUploadRow',
  components: {
    ImageUpload,
  },
  props: {
    valueFromDb: {
      type: Object,
      required: false,
      default() {
        return {
          url: null,
          width: null,
          height: null,
          focalpoint: {
            x: 50,
            y: 50,
          },
        };
      },
    },
    handle: { type: String, default: null },
    label: { type: String, default: null },
    desc: { type: String, default: null },
  },
  data() {
    return {
      inputValue: null,
    };
  },
  created() {
    this.inputValue = this.valueFromDb;
  },
  methods: {
    updateUploadImageObject(imageObject) {
      if (imageObject) {
        this.inputValue = imageObject;
      } else {
        this.inputValue = { url: null };
      }
      this.$emit('update-field', this.inputValue);
    },
  },
};
</script>
