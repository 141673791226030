<template>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label>{{ label }}</label>
    </b-col>
    <b-col cols="12" sm="7">
      <b-form-input
        :id="handle"
        v-model="inputValue"
        debounce="500"
        @input="$emit('update-field', $event)"
      ></b-form-input>
      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <b-form-text v-if="desc" v-html="desc"></b-form-text>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'InputRow',
  props: {
    valueFromDb: { type: String, default: null },
    handle: { type: String, default: null },
    label: { type: String, default: null },
    desc: { type: String, default: null },
  },
  data() {
    return {
      inputValue: null,
    };
  },
  created() {
    this.inputValue = this.valueFromDb;
  },
};
</script>
