var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "mb-4" },
    [
      _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
      _c(
        "b-col",
        { attrs: { cols: "12", sm: "7" } },
        [
          _c(
            "b-form-checkbox",
            {
              attrs: { id: _vm.handle, name: _vm.handle, size: "lg" },
              on: { input: _vm.updateField },
              model: {
                value: _vm.inputValue,
                callback: function($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue"
              }
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          ),
          _vm.desc
            ? _c("b-form-text", { domProps: { innerHTML: _vm._s(_vm.desc) } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }