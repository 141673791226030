<template>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
    </b-col>
    <b-col cols="12" sm="7">
      <b-form-checkbox
        :id="handle"
        :name="handle"
        size="lg"
        v-model="inputValue"
        @input="updateField"
      >
        {{ label }}
      </b-form-checkbox>
      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <b-form-text v-if="desc" v-html="desc"></b-form-text>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'CheckboxRow',
  props: {
    valueFromDb: { type: Boolean, default: false },
    handle: { type: String, default: null },
    label: { type: String, default: null },
    desc: { type: String, default: null },
  },
  data() {
    return {
      inputValue: null,
    };
  },
  created() {
    this.inputValue = this.valueFromDb;
  },
  methods: {
    updateField() {
      this.$emit('update-field', this.inputValue);
    },
  },
};
</script>
